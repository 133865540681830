import { isAndroid, isIOS, isIPadOS } from 'shared/base/IOSDetections';
import { Link } from 'shared/ui/Link';
import { useRouter } from 'next/router';

export function AppPage() {
  const router = useRouter();

  if (isAndroid) {
    router.push(
      'https://play.google.com/store/apps/details?id=pl.wojnawarzyw.app'
    );
  } else if (isIOS || isIPadOS) {
    router.push('https://apps.apple.com/pl/app/wojna-warzyw/id1591022723');
  }

  return (
    <>
      <div className="py-16 bg-gray-50 overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <h1 className="mt-2 mb-8 sm:mb-12 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Pobierz aplikację mobilną
            </h1>
            <div className="flex flex-col items-center">
              <div className="flex flex-row items-center space-x-6 sm:space-x-8">
                <div className="grid gap-4">
                  <Link
                    href="https://apps.apple.com/pl/app/wojna-warzyw/id1591022723"
                    external
                  >
                    <img
                      src={`/assets/app-store.svg`}
                      alt="App Store"
                      className="flex-shrink-0 h-12 sm:h-16 overflow-hidden object-cover"
                    />
                  </Link>
                  <Link
                    href="https://play.google.com/store/apps/details?id=pl.wojnawarzyw.app"
                    external
                  >
                    <img
                      src={`/assets/google-play.svg`}
                      alt="Google Play"
                      className="flex-shrink-0 h-12 sm:h-16 overflow-hidden object-cover"
                    />
                  </Link>
                </div>
                <div className="w-32">
                  <img
                    src={`/assets/qr-wojnawarzyw.svg`}
                    alt="Qr code"
                    className="flex-shrink-0 h-32 overflow-hidden object-cover"
                  />
                  <p className="text-sm">Lub zeskanuj ten kod aparatem</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
