import Head from 'next/head';
import { Header } from '../components/Header';
import { AppPage } from '../components/static/AppPage';
import { DefaultFooter } from '../components/ui/DefaultFooter';

export default function Page() {
  return (
    <>
      <Head>
        <title>Pobierz aplikację mobilną</title>
      </Head>

      <Header />
      <AppPage />
      <DefaultFooter />
    </>
  );
}
